<template>
  <div class="input-group-append">
    <button
      class="btn-showPass"
      @click.prevent="
        showEyeIcon = true;
        showMethod(showEyeIcon);
      "
      v-show="showEyeIcon"
    >
      <i class="far fa-eye-slash" aria-hidden="true"></i>
    </button>
    <button
      class="btn-showPass"
      @click.prevent="
        showEyeIcon = false;
        showMethod(showEyeIcon);
      "
      v-show="!showEyeIcon"
    >
      <i class="far fa-eye" aria-hidden="true"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    showMethod: {
      type: Function,
      required: true,
    },

    showEyeSlash: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showEyeIcon: this.showEyeSlash,
    };
  },

  mounted() {
    // this.showMethod();
  },
};
</script>
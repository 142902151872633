<template>
  <div id="register" class="container-fluid p-0">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="60"
      :width="80"
      :transition="transition"
      loader="spinner"
    ></loading>
    <div class="row bg-reg">
      <div
        class="
          col-md-5
          regBg-info
          fixed-top
          d-sm-none d-md-block d-lg-block d-none
        "
      >
        <h3 class="text-center">{{ $t("welcomeToAfeshop") }}</h3>
        <div class="row">
          <div class="col-12 mt-4 mb">
            <div class="row bg-transparent py-3">
              <div class="col-3 pr-2">
                <div class="illus shadow-sm">
                  <div class="text-center py-3">
                    <i class="mdi mdi-gift-outline"></i>
                  </div>
                </div>
              </div>
              <div class="col-9">
                <h5>{{ $t("illus1Title") }}</h5>
                <p class="mb-0">{{ $t("illus1") }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-8 mb">
            <div class="row bg-transparent py-3">
              <div class="col-3 pr-2">
                <div class="illus shadow-sm">
                  <div class="text-center py-3">
                    <i class="mdi mdi-truck-outline"></i>
                  </div>
                </div>
              </div>
              <div class="col-9">
                <h5>{{ $t("illus2Title") }}</h5>
                <p>{{ $t("illus2") }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-8 mb">
            <div class="row bg-transparent py-3">
              <div class="col-3 pr-2">
                <div class="illus shadow-sm">
                  <div class="text-center py-3">
                    <i class="mdi mdi-lock-check-outline"></i>
                  </div>
                </div>
              </div>
              <div class="col-9">
                <h5>{{ $t("illus3Title") }}</h5>
                <p>{{ $t("illus3") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- desktop form -->
      <div
        class="
          col-md-7
          p-md-8 p-sm-auto p-auto
          regForm
          justify-content-center
          offset-md-5
          d-none d-md-block d-lg-block d-xl-block
        "
      >
        <form class="registerform">
          <h4 class="color-font text-center text-capitalize">
            {{ $t("CreateAccount") }}
          </h4>
          <p class="text-danger mt-3" v-if="typeof errorMsg == 'string'">
            {{ errorMsg }}
          </p>
          <!-- Error Messages -->
          <div class="" v-if="errorList.length > 0">
            <ul
              class="list-unstyled px-3 py-2 border-danger border"
              :class="[errorList.length > 0 ? 'mb-n3 ' : '']"
            >
              <li v-for="error in errorList" :key="error" class="text-danger">
                {{ error }}
              </li>
            </ul>
          </div>

          <!-- First and Last Name Fields -->
          <div class="form-group row mb-n1 mt-3">
            <div class="col-6">
              <label for="firstName">{{ $t("FirstName") }}</label>
              <input
                type="text"
                :class="[
                  'form-control',
                  '',
                  $v.form.firstName.$invalid ? 'is-invalid' : '',
                ]"
                v-model="$v.form.firstName.$model"
                name="firstName"
                id="firstName"
                placeholder="First Name"
              />

              <div class="invalid-feedback" v-if="$v.form.$anyDirty">
                <span v-if="!$v.form.firstName.minLength">{{
                  $t("notLessThan4")
                }}</span>

                <span v-if="!$v.form.firstName.required">{{
                  $t("fistNameRequired")
                }}</span>
              </div>
            </div>
            <div class="col-6">
              <label for="lastName">{{ $t("LastName") }}</label>
              <input
                type="text"
                :class="[
                  'form-control',
                  $v.form.lastName.$invalid ? 'is-invalid' : '',
                ]"
                v-model="$v.form.lastName.$model"
                name="lastName"
                id="lastName"
                placeholder="Last Name"
              />

              <div class="invalid-feedback" v-if="$v.form.$anyDirty">
                <span v-if="!$v.form.lastName.minLength">{{
                  $t("notLessThan4")
                }}</span>

                <span v-if="!$v.form.lastName.required">{{
                  $t("lastNameRequired")
                }}</span>
              </div>
            </div>
          </div>

          <!-- E-mail Field -->
          <div class="form-group">
            <label for="name">{{ $t("E-mail") }}</label>
            <input
              type="email"
              :class="[
                'form-control',
                $v.form.email.$invalid ? 'is-invalid' : '',
              ]"
              v-model="$v.form.email.$model"
              name="email"
              id="email"
              :placeholder="$t('YourEmail')"
            />

            <div class="invalid-feedback" v-if="$v.form.$anyDirty">
              <span v-if="!$v.form.email.email">{{ $t("Invalidemail") }}</span>

              <span v-if="!$v.form.email.required">{{
                $t("emailRequired")
              }}</span>
            </div>
          </div>

          <!-- Password and Confirm Password Fields -->
          <div class="form-group row mt-n4 mb-n1">
            <div class="col-12 col-md-6">
              <label for="password" class="text-capitalize">{{
                $t("password")
              }}</label>
              <label for="password" class="float-right text-secondary text-sm"
                ><button
                  @click.prevent="showPassword"
                  class="d-none d-sm-none d-lg-block d-md-none"
                >
                  {{ $t("ShowPassword") }}?
                </button>
                <ShowPasswordWidget
                  class="d-block d-sm-block d-lg-none d-md-block"
                  :showMethod="showPassword"
                  :showEyeSlash="showEyeSlash"
                />
              </label>

              <input
                :type="showType"
                :class="[
                  'form-control',
                  $v.form.password.$invalid ? 'is-invalid' : '',
                ]"
                v-model="$v.form.password.$model"
                name="password"
                id="password"
                placeholder="Your Password"
              />

              <div class="invalid-feedback" v-if="$v.form.$anyDirty">
                <span v-if="!$v.form.password.minLength">{{
                  $t("passwordAtLeast6")
                }}</span>

                <span v-if="!$v.form.password.required">{{
                  $t("passwordRequired")
                }}</span>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <label for="confirmPassword" class="text-capitalize">{{
                $t("ConfirmPassword")
              }}</label>
              <label
                for="password"
                class="float-right text-capitalize text-secondary text-sm"
                ><button
                  @click.prevent="showPassword"
                  class="d-sm-none d-none d-lg-block d-md-none"
                >
                  {{ $t("ShowPassword") }}?
                </button>
                <ShowPasswordWidget
                  class="d-block d-sm-block d-lg-none d-md-block"
                  :showMethod="showPassword"
                  :showEyeSlash="showEyeSlash"
                />
              </label>

              <input
                :type="showType"
                :class="[
                  'form-control',
                  $v.form.confirmPassword.$invalid ? 'is-invalid' : '',
                ]"
                v-model.trim="$v.form.confirmPassword.$model"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Your Password"
              />

              <div class="invalid-feedback" v-if="$v.form.$anyDirty">
                <span v-if="!$v.form.confirmPassword.sameAsPassword">{{
                  $t("Passworddonotmatch")
                }}</span>
              </div>
            </div>
          </div>

          <!-- Radio buttons -->
          <div>
            <div
              :class="[
                'custom-control',
                'custom-checkbox',
                'pt-2',
                'd-flex',
                'align-items-baseline',
                regPhone ? 'mt-n6' : '',
              ]"
              id="agreeTerms"
            >
              <input
                type="checkbox"
                :class="[
                  'custom-control-input',
                  !form.agreeToTerms &&
                  $v.form.agreeToTerms.required &&
                  formTouched
                    ? 'is-invalid'
                    : '',
                ]"
                id="terms"
                required
                v-model="$v.form.agreeToTerms.$model"
              />
              <label class="custom-control-label radio-inline" for="terms">
                {{ $t("Iagreetoall") }}
                <router-link
                  :to="{ name: 'terms-and-conditions' }"
                  target="_blank"
                  class="text-decorate terms"
                  >{{ $t("terms") }}</router-link
                >
                {{ $t("ofAfeshop") }}
              </label>

              <p
                :class="['mb-0', 'invalid-feedback', 'mt-5']"
                v-if="$v.form.agreeToTerms.required"
              >
                {{ $t("youMustAgree") }}
              </p>
            </div>
            <div
              :class="[
                'custom-control',
                'custom-checkbox',
                'py-4',
                'd-flex',
                'align-items-baseline',
                'mt-n2',
              ]"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                id="customCheck1"
                v-model="form.receiveNews"
              />
              <label
                class="custom-control-label radio-inline"
                for="customCheck1"
                >{{ $t("receiveNewsletters") }}</label
              >
            </div>
          </div>

          <!-- Submit Button -->
          <div>
            <button
              type="submit"
              @click.prevent="submitForm"
              class="
                mt-5
                mb-2
                py-2
                btn btn-lg
                shadow
                btn-block btn-orange
                rounded-0
              "
            >
              <span class="font-15 mx-auto pr-4">{{
                $t("CREATEACCOUNT")
              }}</span>
            </button>

            <!-- Social Media Login Buttons -->
            <social-buttons>
              <template v-slot:google>{{ $t("GoogleSignUp") }} </template>
              <template v-slot:facebook>{{ $t("FacebookSignUp") }} </template>
            </social-buttons>
          </div>

          <!-- {{ $t('Alreadyhaveanaccount') }} -->
          <div class="text-center mt-3 pt-3">
            <p class="mb-3">{{ $t("Alreadyhaveanaccount") }}?</p>
            <a
              href="/customer/signin"
              class="btn btn-link py-1 font-15 rounded-0"
              >{{ $t("LOGININSTEAD") }}</a
            >
          </div>
        </form>
      </div>

      <!-- mobile form -->
      <div
        class="
          col-sm-8 col-12
          offset- offset-sm
          px-0
          regForm
          justify-content-center
          d-block d-sm-block d-md-none
          card
          py-0
          border-0
        "
      >
        <form class="registerform shadow-sm">
          <h4 class="formTitle color-font my-3 text-center text-capitalize">
            {{ $t("CreateAccount") }}
          </h4>
          <p class="text-danger mt-3" v-if="typeof errorMsg == 'string'">
            {{ errorMsg }}
          </p>
          <!-- Error Messages -->
          <div class="" v-if="errorList.length > 0">
            <ul
              class="list-unstyled px-3 py-2 border-danger border"
              :class="[errorList.length > 0 ? 'mb-n3 ' : '']"
            >
              <li v-for="error in errorList" :key="error" class="text-danger">
                {{ error }}
              </li>
            </ul>
          </div>

          <!-- First and Last Name Fields -->
          <div class="form-group row mb-n1 mt-3">
            <div class="col-12 col-sm-6">
              <label for="firstName" class="text-capitalize">{{
                $t("FirstName")
              }}</label>
              <input
                type="text"
                :class="[
                  'form-control',
                  '',
                  $v.form.firstName.$invalid ? 'is-invalid' : '',
                ]"
                v-model="$v.form.firstName.$model"
                name="firstName"
                id="firstName"
                :placeholder="$t('FirstName')"
              />

              <div class="invalid-feedback" v-if="$v.form.$anyDirty">
                <span v-if="!$v.form.firstName.minLength">{{
                  $t("notLessThan4")
                }}</span>

                <span v-if="!$v.form.firstName.required">{{
                  $t("fistNameRequired")
                }}</span>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <label for="lastName" class="text-capitalize">{{
                $t("LastName")
              }}</label>
              <input
                type="text"
                :class="[
                  'form-control',
                  $v.form.lastName.$invalid ? 'is-invalid' : '',
                ]"
                v-model="$v.form.lastName.$model"
                name="lastName"
                id="lastName"
                :placeholder="$t('LastName')"
              />

              <div class="invalid-feedback" v-if="$v.form.$anyDirty">
                <span v-if="!$v.form.lastName.minLength">{{
                  $t("notLessThan4")
                }}</span>

                <span v-if="!$v.form.lastName.required">{{
                  $t("lastNameRequired")
                }}</span>
              </div>
            </div>
          </div>

          <!-- E-mail Field -->
          <div class="form-group">
            <label for="name" class="text-capitalize">{{ $t("E-mail") }}</label>
            <input
              type="email"
              :class="[
                'form-control',
                $v.form.email.$invalid ? 'is-invalid' : '',
              ]"
              v-model="$v.form.email.$model"
              name="email"
              id="email"
              :placeholder="$t('YourEmail')"
            />

            <div class="invalid-feedback" v-if="$v.form.$anyDirty">
              <span v-if="!$v.form.email.email">{{ $t("Invalidemail") }}</span>

              <span v-if="!$v.form.email.required">{{
                $t("emailRequired")
              }}</span>
            </div>
          </div>

          <!-- Password and Confirm Password Fields -->
          <div class="form-group row mt-n4 mb-n1">
            <div class="col-12 col-md-6">
              <label for="password" class="text-capitalize">{{
                $t("password")
              }}</label>
              <label for="password" class="float-right text-secondary text-sm">
                <ShowPasswordWidget
                  class=""
                  :showMethod="showPassword"
                  :showEyeSlash="showEyeSlash"
                />
              </label>

              <input
                :type="showType"
                :class="[
                  'form-control',
                  $v.form.password.$invalid ? 'is-invalid' : '',
                ]"
                v-model="$v.form.password.$model"
                name="password"
                id="password"
                placeholder="Your Password"
              />

              <div class="invalid-feedback" v-if="$v.form.$anyDirty">
                <span
                  v-if="!$v.form.password.minLength"
                  class="text-capitalize"
                  >{{ $t("passwordAtLeast6") }}</span
                >

                <span
                  v-if="!$v.form.password.required"
                  class="text-capitalize"
                  >{{ $t("passwordRequired") }}</span
                >
              </div>
            </div>

            <div class="col-12 col-md-6">
              <label for="confirmPassword" class="text-capitalize">{{
                $t("ConfirmPassword")
              }}</label>
              <label for="password" class="float-right text-secondary text-sm">
                <ShowPasswordWidget
                  class=""
                  :showMethod="showPassword"
                  :showEyeSlash="showEyeSlash"
                />
              </label>

              <input
                :type="showType"
                :class="[
                  'form-control',
                  $v.form.confirmPassword.$invalid ? 'is-invalid' : '',
                ]"
                v-model.trim="$v.form.confirmPassword.$model"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Your Password"
              />

              <div class="invalid-feedback" v-if="$v.form.$anyDirty">
                <span v-if="!$v.form.confirmPassword.sameAsPassword">{{
                  $t("Passwordsdonotmatch")
                }}</span>
              </div>
            </div>
          </div>

          <!-- Radio buttons -->
          <div>
            <div
              :class="[
                'custom-control',
                'custom-checkbox',
                'pt-2',
                'd-flex',
                'align-items-baseline',
                regPhone ? 'mt-n6' : '',
              ]"
              id="agreeTerms"
            >
              <input
                type="checkbox"
                :class="[
                  'custom-control-input',
                  !form.agreeToTerms &&
                  $v.form.agreeToTerms.required &&
                  formTouched
                    ? 'is-invalid'
                    : '',
                ]"
                id="terms"
                required
                v-model="$v.form.agreeToTerms.$model"
              />
              <label class="custom-control-label radio-inline" for="terms">
                {{ $t("Iagreetoall") }}
                <router-link
                  :to="{ name: 'terms-and-conditions' }"
                  target="_blank"
                  class="text-decorate terms"
                  >{{ $t("terms") }}</router-link
                >
                <!-- @click.prevent="$root.$emit('showModal', true)" -->
                {{ $t("ofAfeshop") }}
              </label>

              <p
                :class="['mb-0', 'invalid-feedback', 'mt-5']"
                v-if="$v.form.agreeToTerms.required"
              >
                {{ $t("youMustAgree") }}
              </p>
            </div>
            <div
              :class="[
                'custom-control',
                'custom-checkbox',
                'py-4',
                'd-flex',
                'align-items-baseline',
                'mt-n2',
              ]"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                id="customCheck1"
                v-model="form.receiveNews"
              />
              <label
                class="custom-control-label radio-inline"
                for="customCheck1"
                >{{ $t("receiveNewsletters") }}</label
              >
            </div>
          </div>

          <!-- Submit Button -->
          <div>
            <button
              type="submit"
              @click.prevent="submitForm"
              class="
                mt-5
                mb-2
                py-2
                btn btn-lg
                shadow
                btn-block btn-orange
                rounded-0
              "
            >
              <span class="font-15 mx-auto pr-4">{{
                $t("CREATEACCOUNT")
              }}</span>
            </button>

            <!-- Social Media Login Buttons -->
            <social-buttons
              :links="{ google: 'google.com', facebook: 'facebook.com' }"
            >
              <template v-slot:google>{{ $t("GoogleSignUp") }} </template>
              <template v-slot:facebook>{{ $t("FacebookSignUp") }} </template>
            </social-buttons>
          </div>

          <!-- {{ $t('Alreadyhaveanaccount') }} -->
          <div class="text-center mt-3 pt-3">
            <p class="mb-3">{{ $t("Alreadyhaveanaccount") }}?</p>
            <a
              href="/customer/signin"
              class="btn btn-link py-1 font-15 rounded-0"
              >{{ $t("LOGININSTEAD") }}</a
            >
          </div>
        </form>
      </div>

      <!-- Terms and conditions modal -->
      <!-- <terms-modal></terms-modal> -->
    </div>
  </div>
</template>

<script>
import TermsModal from "@/components/customer/TermsModal.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "@/assets/css/customer/signup/signup.css";
import SocialButtons from "@/components/widgets/SocialButtons.vue";

import ShowPasswordWidget from "@/components/widgets/ShowPassword.vue";

import $ from "jquery";
export default {
  components: {
    SocialButtons,
    Loading,
    TermsModal,
    ShowPasswordWidget,
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",

        password: "",
        confirmPassword: "",
        receiveNews: false,
        agreeToTerms: false,
      },

      show_modal: false,
      phone: false,
      submitRequest: false,
      loading: false,
      transition: "slide-fade",

      uiState: "submit not clicked",
      errors: false,
      empty: true,
      formTouched: false,
      errorMsg: "",
      errorList: [],
      showType: "password",
      showEyeSlash: true,
    };
  },

  validations: {
    form: {
      firstName: {
        minLength: minLength(4),
        required,
      },

      lastName: {
        minLength: minLength(4),
        required,
      },

      email: {
        required,
        email,
      },

      password: {
        required,
        minLength: minLength(6),
      },

      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
      agreeToTerms: {
        required,
      },
    },
  },

  methods: {
    countryChanged(country) {
      this.country = country;
      this.countryCode = country.iso2;
    },

    ...mapMutations({
      registerSuccess: "registerSuccess",
    }),

    ...mapActions(["register"]),

    showModal() {
      let element = this.$refs.modal.$el;
      $(element).modal("show");
    },

    showPassword(value) {
      if (this.showType === "password") {
        this.showType = "text";
        this.showEyeSlash = value;
      } else {
        this.showType = "password";
        this.showEyeSlash = value;
      }
    },

    formRegister() {
      console.log("form registration: ", {
        name: this.form.firstName + " " + this.form.lastName,
        email: this.form.email,
        password: this.form.password,
      });

      this.register({
        name: this.form.firstName + " " + this.form.lastName,
        email: this.form.email,
        password: this.form.password,
        has_agree: this.form.agreeToTerms,
      }).then((response) => {
        console.log("response register ", response);
        if (response.code === "USERS_CREATED") {
          this.loading = false;
          console.log("registration 200:", response);
          this.registerSuccess(response);
        } else {
          this.loading = false;
          this.errorMsg = response.data.msg;
          var errors = new Set();
          if (typeof this.errorMsg == "object") {
            Object.values(this.errorMsg).forEach((val) => {
              errors.add(val[0]);
            });
          }
          this.errorList = [...errors];
          console.log("registration error code: ", this.errorMsg);
          console.log("registration error code: ", response);
        }
      });
    },

    submitForm() {
      this.$v.$touch();
      this.submitRequest = true;

      this.formTouched = this.$v.form.$anyDirty;
      this.errors = this.$v.form.$anyError;
      this.uiState = "submit clicked";

      console.log("entered");
      console.log(
        "phone; ",
        this.form.phoneNumberOpt,
        "\nname: ",
        this.form.firstName
      );

      if (this.errors === false && this.formTouched === true) {
        this.uiState = "form submitted";

        console.log("form valid");
        if (this.form.agreeToTerms) {
          this.loading = true;
          console.log(this.form.firstName + this.form.lastName);
          this.formRegister();
        }
      }
    },

    validatePhone({ number, isValid, country }) {
      this.validPhone = isValid;
      console.log("validatePhone: ", number, isValid, country);

      if (isValid) {
        this.bindProps.inputClasses = "form-control is-valid";
      } else {
        this.bindProps.inputClasses = "form-control is-invalid";
      }
    },

    validatePhoneOpt({ number, isValid, country }) {
      this.validPhoneOpt = isValid;
      console.log("validatePhoneOpt: ", isValid);
      if (isValid) {
        this.bindProps2.inputClasses = "form-control is-valid";
      } else {
        this.bindProps2.inputClasses = "form-control is-invalid";
      }
    },
  },

  computed: {
    ...mapGetters(["registrationError"]),

    regPhone: {
      get() {
        return this.$store.state.registerWithPhone;
      },
      set(val) {
        this.$store.commit("SET_REGPHONE", val);
      },
    },

    regWP() {
      return this.regP;
    },

    messageClass() {
      return {
        "md-invalid": this.$v.form.$anyDirty,
      };
    },

    phoneOptIsValid() {
      return this.validPhoneOpt;
    },
  },

  mounted() {
    this.$root.$on("agreeToTerms", (value) => {
      this.form.agreeToTerms = value;
      console.log("agree to terms emitted: ", this.form.agreeToTerms);
    });
  },
};
</script>

<style scoped></style>

<template>
  <div class="row">
    <!-- Google Login -->
    <div class="col-md-12 col-12 col-lg-6">
      <a
        class="
          mt-3
          btn btn-lg btn-link
          shadow
          btn-block
          rounded-0
          d-flex
          justify-content-left
          align-items-center
          bg-danger
          py-3
        "
        @click.prevent="socialAuthenticate('google')"
      >
        <span class="my-">
          <i class="fab fa-google-plus-g"></i>
        </span>
        <span class="font-15 my- mx-auto px-3">
          <slot name="google"></slot>
        </span>
      </a>
    </div>

    <!-- Facebook Login -->
    <div class="col-md-12 col-12 col-lg-6 fb">
      <a
        class="
          mt-3
          btn btn-lg btn-link
          shadow
          btn-block
          rounded-0
          d-flex
          justify-content-left
          align-items-center
          btn-blue
          py-3
        "
        @click.prevent="socialAuthenticate('facebook')"
      >
        <span class="">
          <i class="fab fa-facebook-f"></i>
        </span>
        <span class="font-15 mx-auto px-3">
          <slot name="facebook"></slot>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import "@/assets/css/customer/signin/signin.css";
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(["socialAuth"]),

    socialAuthenticate(authType) {
      this.socialAuth(authType).then((response) => {
        let url = response.data.url;
        window.location.href = url;
        console.log("Social Auth response: ", url);
      });
    },
  },
};
</script>

<style scoped>
span {
  color: white;
}
@media (max-width: 991px) {
  .gl,
  .fb {
    margin-top: -22px;
  }
}
</style>


<template>
  <modal
    title="Terms and Conditions!"
    :is-small="false"
    v-if="show_modal"
    @close="show_modal = false"
  >
    <div class="modal-body">
      <p></p>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-danger" @click="show_modal = false">
        {{ $t("Close") }}
      </button>
      <button
        type="button"
        class="btn btn-orange"
        @click="
          $root.$emit('agreeToTerms', true);
          show_modal = false;
        "
      >
        {{ $t("Accept") }}
      </button>
    </div>
  </modal>
</template>

<script>
import modal from "@/views/customer/comments/ModalView";

export default {
  components: {
    modal,
  },

  data() {
    return {
      show_modal: false,
    };
  },

  watch: {
    show_modal(val) {
      console.log("Show modal:", val);
    },
  },

  mounted() {
    this.$root.$on("showModal", (value) => {
      this.show_modal = value;
    });
  },
};
</script>

<style scoped>
.pos {
  position: absolute;
  z-index: 0;
  left: 0px;
  top: 0px;
}
</style>
